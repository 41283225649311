function appInit($) {

'use strict';
console.log('jQuery Version is ' + $.fn.jquery);

// Common elements
var $window = $(window);
var $html = $('html');
var $body = $('body');
var $root = $('#root');
var $header = $('#header');

// Window Size
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;

// Breakpoint for responsive JS
var breakpoint = {
	tablet: 768,
	desktop: 1024,
}

/**
 * Global Navigation Shadow
 */
$window.on('scroll', function(e) {
	var scrollTop = $window.scrollTop();
	if ( scrollTop > 0 ) $header.addClass('shadow');
	else $header.removeClass('shadow');
});

/**
 * Global Navigation Toggle (Mobile)
 */
var $navOpenBtn =  $('#navOpenBtn');
var $globalNavClose = $('#globalNavCloseAnchor, #globalNavCloseLayer');
var globalNav = document.getElementById('global-nav');

function globalNavScrollEvent (e) {
	if ( this.scrollHeight - this.scrollTop === this.clientHeight )
		e.preventDefault();
}

// Open
$navOpenBtn.click(function (e) {
	e.preventDefault();

	$html.addClass('overflow-hidden');
	// $body.addClass('overflow-y-hidden');
	$header.addClass('shown');

	//globalNav.addEventListener('scroll', globalNavScrollEvent, false);

	//console.log('scrollHeight: ' + globalNav.scrollHeight);
	//console.log('clientHeight: ' + globalNav.clientHeight);
});

// Close
$globalNavClose.click(function (e) {
	e.preventDefault();

	$html.removeClass('overflow-hidden');
	// $body.removeClass('overflow-y-hidden');
	$header.removeClass('shown');

	//globalNav.removeEventListener('scroll', globalNavScrollEvent);
});

/**
 * Email address decription
 * A wizard to generate this code is at http://www.jottings.com/obfuscator/
 */
var emailLinks = document.querySelectorAll('.emailLink');
var coded = "QIh4BWBAc@awRcg.VIw";
var key = "wcEXAtLj0kf19RI2UKlCVnyDSHsGWzdxpqFvYgeuhPTmib8r4MJZ5O76aoBQN3";
var shift = coded.length;
var link = "";

// Decript link
for (var i=0; i < coded.length; i++) {
	if (key.indexOf(coded.charAt(i))==-1) {
		var ltr = coded.charAt(i)
		link += (ltr)
	}
	else {
		var ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length
		link += (key.charAt(ltr))
	}
}

// Add link
for (var i=0, anchor; anchor=emailLinks[i]; i++) {
	anchor.setAttribute('href', 'mailto:'+link);
	anchor.innerHTML = link;
}

/**
 * Smooth scrolling
 */
$('a[href*="#"]:not([href="#"])').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 1000);
			return false;
		}
	}
});

/**
 * Append empty <li> into .page-sub-nav
 */
var $pageSubnav = $('.page-sub-nav');
var liEl = document.createElement('li');
$pageSubnav.append(liEl);

/**
 * Responsive JS
 */
function onResizeCallback() {
	var newWindowWidth = window.innerWidth;
	if ( newWindowWidth === windowWidth) return;

	// Update windowWidth
	windowWidth = newWindowWidth;

	// Mobile
	if (windowWidth < breakpoint.tablet) {
		return;
	}

	// Tablet
	if (windowWidth >= breakpoint.tablet) {
		return;
	}

	// Desktop
	if (windowWidth >= breakpoint.tablet) {
		return;
	}
}
$window.on('resize', onResizeCallback);
// Initialize
onResizeCallback();

} // End appInit